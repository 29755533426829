import { useEffect, useState } from "react";
import { useFilter } from "../../../hooks/useFilter";
import { ReactComponent as CattleMap } from "../../../assets/svg/icons/cattle.svg"
import { DateModal } from "../../../components/Modal/DateFilter";
import axios from "../../../services/axios"
import { format } from "date-fns";
import GraphBarLine from "../../../components/Dashboard/Graph/BarLine/CattleMap";
import "./styles.css"
import Constants from "../../../constants/Index";


export default function DashboardCattleMap() {
  const {
    setHideMicroAreaFilter,
    setHideBatchFilter,
    farmFilter,
    areaFilter,
    rangeDays,
    setRangeDays,
    setDashboard,
    balance,
    setBalance,
    setBalanceCapacity,
    setSideBarOptionSelected,
  } = useFilter();

  const [balanceCapacityMap, setBalanceCapacityMap] = useState<Map<Number, any> | undefined>(undefined);
  const [balanceAnimal, setBalanceAnimal] = useState<IBalanceAnimal[]>();

  const mapMonth = new Map<Number, any>();

  const months = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]

  useEffect(() => {
    setHideMicroAreaFilter(true)
    setHideBatchFilter(true)
    setSideBarOptionSelected("DASHBOARD")
    setDashboard("CATTLE_MAP")
    document.title = Constants.TITLE_DASHBOARD;
  }, []);

  useEffect(() => {
    if (rangeDays && areaFilter) {
      if (farmFilter?.id && areaFilter?.id && rangeDays?.from && rangeDays?.to) {

        axios.get("/balance", {
          params: {
            farmId: farmFilter.id,
            areaId: areaFilter.id,
            startDate: format(new Date(rangeDays.from), 'yyyy-MM-dd HH:mm:ss'),
            endDate: format(new Date(rangeDays.to), 'yyyy-MM-dd HH:mm:ss')
          }
        }).then((response) => {
          setBalance(response.data)
        })

        axios.get("/balance/animal-weight-category", {
          params: {
            farmId: farmFilter.id,
            areaId: areaFilter.id,
            startDate: format(new Date(rangeDays.from), 'yyyy-MM-dd HH:mm:ss'),
            endDate: format(new Date(rangeDays.to), 'yyyy-MM-dd HH:mm:ss')
          }
        }).then((response) => {
          setBalanceAnimal(response.data)
        })

        axios.get("/balance/capacity", {
          params: {
            farmId: farmFilter.id,
            areaId: areaFilter.id,
            startDate: format(new Date(rangeDays.from), 'yyyy-MM-dd HH:mm:ss'),
            endDate: format(new Date(rangeDays.to), 'yyyy-MM-dd HH:mm:ss')
          }
        }).then((response) => {
          for (let i = 0; i < 12; i++) {
            mapMonth.set(i, [])
          }

          setBalanceCapacity(response.data)
          response?.data?.forEach((item: IBalanceCapacity) => {
            const monthReference = item?.month_reference;
            if (mapMonth.has(monthReference) && mapMonth.get(monthReference) != undefined) {
              mapMonth.set(monthReference, [
                ...mapMonth.get(monthReference),
                item
              ])
            } else {
              mapMonth.set(monthReference, [item])
            }
          });
          setBalanceCapacityMap(mapMonth)
        })
      }
    }
  }, [rangeDays, areaFilter]);

  return (
    <div className="cattleMap_background">
      <DateModal setRange={(el) => setRangeDays(el)} />

      <div className="cattleMap_page">
        <div className="cattleMap_header">
          <CattleMap height={72} />
          <div style={{
            display: "flex",
            flexDirection: "column",
          }}>
            <h2 className="cattleMap_title">
              Mapa de Gado
            </h2>
            <h3 className="cattleMap_subtitle">
              Visão Geral
            </h3>
          </div>
        </div>

        {balance != undefined &&
          <div className="tableDashboard" id={"saldoEstoque"}>
            <h2>saldo do estoque</h2>
            <table>
              <thead style={{
                color: "#fff"
              }}>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "150px",
                  textAlign: "left"
                }}>
                  Categoria Animal
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "80px"
                }}>
                  Saldo Inicial
                </th>
                <th scope="col" colSpan={4} style={{
                  textAlign: "center",
                  background: "#62b8ae"
                }}>
                  Entrada
                </th>
                <th scope="col" colSpan={4} style={{
                  textAlign: "center",
                  background: "#ff6161"
                }}>
                  Saída
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "80px"
                }}>
                  Evolução
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#57CE71",
                  width: "80px"
                }}>
                  Saldo Final
                </th>
                <tr>
                  <th scope="col" style={{
                    background: "#324440",
                    width: "80px"
                  }}>
                    Nasc.
                  </th>
                  <th scope="col" style={{
                    background: "#324440",
                    width: "80px"
                  }}>
                    Transf. Interna
                  </th>
                  <th scope="col" style={{
                    background: "#324440",
                    width: "80px"
                  }}>
                    Transf. Externa
                  </th>
                  <th scope="col" style={{
                    background: "#324440",
                    width: "80px"
                  }}>
                    Compras
                  </th>
                  <th scope="col" style={{
                    background: "#324440",
                    width: "80px"
                  }}>
                    Venda
                  </th>
                  <th scope="col" style={{
                    background: "#324440",
                    width: "80px"
                  }}>
                    Transf. Interna
                  </th>
                  <th scope="col" style={{
                    background: "#324440",
                    width: "80px"

                  }}>
                    Transf. Externa
                  </th>
                  <th scope="col" style={{
                    background: "#324440",
                    width: "80px"
                  }}>
                    Morte
                  </th>
                </tr>
              </thead>

              <tbody>
                {
                  balance.sort((a, b) => a.id - b.id)?.map((item, index) => (
                    <tr className={index % 2 === 0 ? " bg_white" : "bg_gray"}>
                      <th scope="row">{item.name} {(item.age_description).toLowerCase()}</th>
                      <td>{item.opening_balance}</td>
                      <td>{item.birth}</td>
                      <td>{item.internal_in_transfer}</td>
                      <td>{item.external_in_transfer}</td>
                      <td>{item.shopping}</td>
                      <td>{item.sales * -1}</td>
                      <td>{item.internal_out_transfer}</td>
                      <td>{item.external_out_transfer}</td>
                      <td>{item.death}</td>
                      <td>{item.evolution}</td>
                      <td>{item.end_balance}</td>
                    </tr>
                  ))
                }
              </tbody>

              <tfoot
                style={{
                  background: "#324440",
                  color: "#fff"
                }}
              >
                <th style={{
                  textAlign: "left"
                }}>Total</th>
                <td>{balance?.reduce((acc, item) => acc + item.opening_balance, 0)}</td>
                <td>{balance?.reduce((acc, item) => acc + item.birth, 0)}</td>
                <td>{balance?.reduce((acc, item) => acc + item.internal_in_transfer, 0)}</td>
                <td>{balance?.reduce((acc, item) => acc + item.external_in_transfer, 0)}</td>
                <td>{balance?.reduce((acc, item) => acc + item.shopping, 0)}</td>
                <td>{balance?.reduce((acc, item) => acc + item.sales * -1, 0)}</td>
                <td>{balance?.reduce((acc, item) => acc + item.internal_out_transfer, 0)}</td>
                <td>{balance?.reduce((acc, item) => acc + item.external_out_transfer, 0)}</td>
                <td>{balance?.reduce((acc, item) => acc + item.death, 0)}</td>
                <td>{balance?.reduce((acc, item) => acc + item.evolution, 0)}</td>
                <td>{balance?.reduce((acc, item) => acc + item.end_balance, 0)}</td>
              </tfoot>
            </table>
          </div>
        }

        {balanceAnimal != undefined &&
          <div className="tableDashboard" id={"categorias"}>
            <h2>Categoria animal por peso e ua</h2>
            <table>
              <thead style={{
                color: "#fff"
              }}>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "150px",
                  textAlign: "left"
                }}>
                  Categoria Animal
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#57CE71",
                  width: "80px"
                }}>
                  Estoque final
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "100px"
                }}>
                  Peso médio (Kg)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "100px"
                }}>
                  Peso total (Kg)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "100px"
                }}>
                  Total @s
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "80px"
                }}>
                  Uas total
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "80px"
                }}>
                  Distr. UAs
                </th>
              </thead>

              <tbody>
                {
                  balanceAnimal.sort((a, b) => a.category_id - b.category_id)?.map((item, index) => (
                    <tr className={index % 2 === 0 ? " bg_white" : "bg_gray"}>
                      <th scope="row">{item.name} {(item.age_description).toLowerCase()}</th>
                      <td>{item.quantity}</td>
                      <td>{item.average_weight}</td>
                      <td>{item.total_weight}</td>
                      <td>{item.total_arroba}</td>
                      <td>{item.total_uas}</td>
                      <td>{((item.total_uas / balanceAnimal?.reduce((acc, item) => acc + item.total_uas, 0)) * 100).toFixed(2)} %</td>
                    </tr>
                  ))
                }
              </tbody>

              <tfoot
                style={{
                  background: "#324440",
                  color: "#fff"
                }}
              >
                <th style={{
                  textAlign: "left"
                }}>Total</th>
                <td>{balanceAnimal?.reduce((acc, item) => acc + item.quantity, 0)}</td>
                <td></td>
                <td>{balanceAnimal?.reduce((acc, item) => acc + item.total_weight, 0)}</td>
                <td>{balanceAnimal?.reduce((acc, item) => acc + item.total_arroba, 0)}</td>
                <td>{balanceAnimal?.reduce((acc, item) => acc + item.total_uas, 0)}</td>
                <td>100 %</td>
              </tfoot>
            </table>
          </div>
        }

        {balanceCapacityMap != undefined &&
          <div className="tableDashboard">
            <h2>Curva de estoque e lotação</h2>
            <table>
              <thead style={{
                color: "#fff"
              }}>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "150px",
                  textAlign: "left"
                }}>
                  Lotação/Mês
                </th>
                {
                  months.map((month, index) =>
                    <th scope="col" style={{
                      background: balanceCapacityMap.get(index)[0] ?
                        balanceCapacityMap.get(index)[0]?.rain == 0 ? "#D9B43C" : "#62B8AE" :
                        "#808080",
                      width: "80px",
                      height: "60px",
                    }}>
                      <span className="cattleMap_month">{index + 1}</span>
                      <br />
                      <span>{month}</span>
                    </th>
                  )
                }
              </thead>

              <tbody>
                <tr className="bg_white">
                  <th scope="row">Nº de Animais</th>
                  {Array.from(balanceCapacityMap.values()).map((item: IBalanceCapacity[]) => (
                    <td>
                      {item.length > 0 ? item.reduce((acc, item) => acc + item.quantity, 0) : "-"}
                    </td>
                  ))}
                </tr>
                <tr className="bg_gray">
                  <th scope="row">Lotação média (UAs)</th>
                  {Array.from(balanceCapacityMap.values()).map((item: IBalanceCapacity[]) => (
                    <td>
                      {
                        item.length > 0 ?
                          (item.reduce((acc, item) => acc + item.ua_total, 0)
                            / item.reduce((acc, item) => acc + item.total_area, 0)
                          ).toFixed(2)
                          : "-"
                      }
                    </td>
                  )
                  )}
                </tr>
              </tbody>
              <div>

                <GraphBarLine
                  optionsColumnRain={
                    Array.from(balanceCapacityMap.values()).map((item: IBalanceCapacity[]) => (
                      {
                        label: "",
                        value: item[0]?.rain == 1 ? (item.reduce((acc, item) => acc + item.quantity, 0)) : 0
                      }
                    ))
                  }

                  optionsColumnDry={
                    Array.from(balanceCapacityMap.values()).map((item: IBalanceCapacity[]) => (
                      {
                        label: "",
                        value: item[0]?.rain == 0 ? (item.reduce((acc, item) => acc + item.quantity, 0)) : 0
                      }
                    ))
                  }

                  optionsLine={
                    Array.from(balanceCapacityMap.values()).map((item: IBalanceCapacity[]) => (
                      {
                        label: "",
                        value: Number((item.reduce((acc, item) => acc + item.ua_total, 0)
                          / item.reduce((acc, item) => acc + item.total_area, 0)
                        ).toFixed(2))
                      }
                    ))
                  }
                />
              </div>
            </table>
          </div>
        }
      </div>
    </div>
  );
}
