import { ReactNode, useEffect, useState } from "react";
import { IAreas } from "../../../../@types/API/IArea";
import { IBatches } from "../../../../@types/API/IBatches";
import { IFarms } from "../../../../@types/API/IFarm";
import { IMicroAreas } from "../../../../@types/API/IMicroArea";
import { ICoords } from "../../../../@types/GoogleMaps/ICoord";
import { IOptionProps } from "../../../../@types/reactSelect/IOption";
import { LeafletMap } from "../../../../components/Leaflet/LeafletMap/Index";
import { FilterDataPolygons } from "../../../../components/PaddocksMap/FilterDataPolygons/Index";
import { useFilter } from "../../../../hooks/useFilter";
import { getCenterCoords } from "../../../../utils/leaflet/getCenterCoords";
import { ViewDataCattleMap } from "../../../../components/Dashboard/ViewDatas/CattleMap";
import { ViewDataSupplementation } from "../../../../components/Dashboard/ViewDatas/Supplementation";

type MapsIndexProps = {
  children: ReactNode;
};

type ICustomFarm = {
  id: string;
  initials: string;
  coords: ICoords;
  name: string;
  bgColor: string;
};

type ICustomArea = {
  id: string;
  initials: string;
  coords: ICoords;
  name: string;
  bgColor: string;
  farmId: string;
};

export function Index({ children }: MapsIndexProps) {
  const {
    farmFilter,
    setFarmFilter,
    areaFilter,
    setAreaFilter,
    setMicroArea,
    hideMicroAreaFilter,
    setMicroAreaDefaultOption,
    microAreaDefaultOption,
    setBatchFilter,
    setBatchDefaultOption,
    batchDefaultOption,
    hideBatchFilter,
    sideBarOptionSelected,
    dashboard
  } = useFilter();

  const [farmsData, setFarms] = useState<IFarms>([]);
  function getFarmsFiltereds(farms: IFarms) {
    const [currentFiltered] = farms;
    const current = farmsData.find((f) => f.id === currentFiltered.id);
    if (current) {
      const { id, initials, name, map_coords: coords } = current;
      const customFarm = { id, initials, name, coords } as ICustomFarm;
      setFarmFilter(customFarm);
    }
    setAreaFilter(undefined);
    setMicroArea(undefined);
    setMicroAreaDefaultOption(undefined);
    setBatchDefaultOption(undefined);
    setBatchFilter(undefined);
  }

  function defaultValueFarm() {
    if (!farmFilter) return undefined;
    return {
      label: `${farmFilter.name} (${farmFilter.initials})`,
      value: farmFilter?.id,
    } as IOptionProps;
  }

  const [areasData, setAreasData] = useState<IAreas>([]);
  function getAreasFiltereds(areas: IAreas) {
    const [currentFiltered] = areas;
    if (currentFiltered) {
      const current = areasData.find((f) => f.id === currentFiltered.id);
      if (current && farmFilter) {
        const { id, initials, name, map_coords: coords } = current;
        const customArea = { id, initials, name, coords, farmId: farmFilter.id as string } as ICustomArea;
        setAreaFilter({ ...customArea, bgColor: "" });
      }
    } else setAreaFilter(currentFiltered);
    setMicroArea(undefined);
    setBatchFilter(undefined);
    setMicroAreaDefaultOption(undefined);
    setBatchDefaultOption(undefined);
  }

  function defaultValueArea() {
    if (!areaFilter) return undefined;
    return {
      label: `${areaFilter.name} (${areaFilter.initials})`,
      value: areaFilter?.id,
    } as IOptionProps;
  }

  function getMicroAreasFiltereds(microAreas: IMicroAreas) {
    const [microArea] = microAreas;
    if (microArea) {
      const { area_id, id, initials, map_coords, micro_area_route } = microArea as any;
      setMicroArea({
        areaId: area_id,
        id,
        coords: map_coords,
        initials,
        microAreaRoute: {
          batch: micro_area_route ? micro_area_route.batch : null,
          current: micro_area_route ? micro_area_route.micro_area_route : false,
        },
      });
    } else setMicroArea(undefined);
  }

  function getBatchesFiltereds(batches: IBatches) {
    const [batch] = batches;
    if (batch) {
      setBatchFilter({
        areaId: batch.area.id,
        capacityRate: batch.batch_capacity_rate,
        coords: getCenterCoords(batch.current_micro_area.map_coords),
        farmId: farmFilter.id,
        id: batch.id,
        initials: batch.initials,
        microAreaId: batch.current_micro_area.id,
      });
    } else setBatchFilter(undefined);
  }

  useEffect(() => {
    if (!farmFilter && farmsData.length > 0) {
      const [farm] = farmsData;
      const { id, initials, map_coords: coords, name } = farm;
      setFarmFilter({
        id,
        initials,
        coords,
        name,
      } as ICustomFarm);
    }
  }, [farmFilter, farmsData]);

  return (
    <>
      <section className="paddocktime__container">
        <FilterDataPolygons
          dropdownFarmProps={{
            getFarmsData(farms) {
              setFarms(farms);
            },
            getFarmsFiltereds: getFarmsFiltereds,
            defaultValue: defaultValueFarm(),
          }}
          dropdownAreaProps={{
            placeholder: "Selecione o retiro",
            clearableLabel: "todos os retiros",
            clearable: true,
            getAreasData(areas) {
              setAreasData(areas);
            },
            getAreasFiltereds: getAreasFiltereds,
            defaultValue: defaultValueArea(),
          }}
          searchMicroAreaProps={{
            placeHolder: "Pesquisar Pasto...",
            getMicroAreasFiltereds: getMicroAreasFiltereds,
            exportDefaultOption(option) {
              setMicroAreaDefaultOption(option);
            },
            defaultOption: microAreaDefaultOption,
          }}
          searchLotsProps={{
            placeHolder: "Pesquisar Lote...",
            getBatchesFiltereds: getBatchesFiltereds,
            exportDefaultOption(option) {
              setBatchDefaultOption(option);
            },
            defaultOption: batchDefaultOption,
          }}
          hideMicroAreas={hideMicroAreaFilter}
          hideLots={hideBatchFilter}
        />

        {sideBarOptionSelected == "DASHBOARD" && dashboard == "CATTLE_MAP" && <ViewDataCattleMap />}
        {/* {sideBarOptionSelected == "DASHBOARD" && dashboard == "SUPPLEMENTATION" && <ViewDataSupplementation />} */}
      </section>

      {sideBarOptionSelected != "DASHBOARD" ? <LeafletMap>{children}</LeafletMap> : children}
    </>
  );
}
