import { ReactComponent as CalendarIcon } from "../../../../assets/svg/icons/calendar.svg";
import { format } from "date-fns";
import { useFilter } from "../../../../hooks/useFilter";
import "../styles.css"

export function ViewDataSupplementation() {
    const {
        setModalCalendarFilter,
        rangeDays
    } = useFilter();

    return (
        <section className="dashBoardSupplement__container" style={{ marginTop: 30 }}>
            <div className="dashBoardInfoSupplement__container">
                <div className="dataFilter__owner--container" onClick={() => setModalCalendarFilter(true)}>
                    <small>De</small>
                    <div>
                        {
                            rangeDays?.from ? (
                                <h6>{format(new Date(rangeDays.from), 'dd/MM/yyyy')}</h6>
                            ) : null
                        }
                        <CalendarIcon />
                    </div>
                </div>

                <div className="dataFilter__owner--container" onClick={() => setModalCalendarFilter(true)}>
                    <small>Até</small>
                    <div>
                        {
                            rangeDays?.to ? (
                                <h6>{format(new Date(rangeDays.to), 'dd/MM/yyyy')}</h6>
                            ) : null
                        }
                        <CalendarIcon />
                    </div>
                </div>
            </div>

            <button
                className="dashBoardInfo__exportButton no-print"
                onClick={() => window.print()}
            >
                Exportar PDF
            </button>
        </section>
    );
}