import { useEffect, useState } from "react";
import { useFilter } from "../../../../hooks/useFilter";
import { DateModal } from "../../../../components/Modal/DateFilter";
import { ReactComponent as BagFood } from "../../../../assets/svg/icons/bagFood.svg"
import { ViewDataSupplementation } from "../../../../components/Dashboard/ViewDatas/Supplementation";
import Constants from "../../../../constants/Index";
import axios from "../../../../services/axios"
import { format } from "date-fns";

export default function DashboardSupplementationInventoryControl() {
  const {
    farmFilter,
    areaFilter,
    rangeDays,
    setHideMicroAreaFilter,
    setHideBatchFilter,
    setDashboard,
    setSideBarOptionSelected,
    setRangeDays
  } = useFilter();

  const [centralWarehouse, setCentralWarehouse] = useState<ICentralWarehouseStock[]>();
  const [depositeInPasture, setDepositeInPasture] = useState<IDepositInPasture[]>();

  useEffect(() => {
    setHideMicroAreaFilter(true)
    setHideBatchFilter(true)
    setSideBarOptionSelected("DASHBOARD")
    setDashboard("SUPPLEMENTATION")
    document.title = Constants.TITLE_DASHBOARD;
  }, []);

  useEffect(() => {
    if (rangeDays && areaFilter) {
      if (farmFilter?.id && areaFilter?.id && rangeDays?.from && rangeDays?.to) {

        axios.get("/supplement/central-warehouse-stock", {
          params: {
            farmId: farmFilter.id,
            areaId: areaFilter.id,
            startDate: format(new Date(rangeDays.from), 'yyyy-MM-dd HH:mm:ss'),
            endDate: format(new Date(rangeDays.to), 'yyyy-MM-dd HH:mm:ss')
          }
        }).then((response) => {
          setCentralWarehouse(response.data)
        })

        axios.get("/supplement/general-analysis", {
          params: {
            farmId: farmFilter.id,
            areaId: areaFilter.id,
            startDate: format(new Date(rangeDays.from), 'yyyy-MM-dd HH:mm:ss'),
            endDate: format(new Date(rangeDays.to), 'yyyy-MM-dd HH:mm:ss')
          }
        }).then((response) => {
          // setBalanceAnimal(response.data)
        })

        axios.get("/supplement/stock-deposit-in-the-pasture", {
          params: {
            farmId: farmFilter.id,
            areaId: areaFilter.id,
            startDate: format(new Date(rangeDays.from), 'yyyy-MM-dd HH:mm:ss'),
            endDate: format(new Date(rangeDays.to), 'yyyy-MM-dd HH:mm:ss')
          }
        }).then((response) => {
          setDepositeInPasture(response.data)
        })
      }
    }
  }, [rangeDays, areaFilter]);

  const IconMarq = (value: number, min: number, max: number) => {
    let hex = "FFFFFF";

    if (value <= min) {
      hex = "FF0000";
    } else if (value >= min && value <= max) {
      hex = "FFFF00";
    } else {
      hex = "00FF00";
    }

    return (
      <div style={{
        width: 20,
        height: 20,
        background: `#${hex}`,
        borderRadius: 50,
        border: "1px solid #FFF"
      }} />
    )
  }

  return (
    <div className="cattleMap_background">
      <DateModal setRange={(el) => setRangeDays(el)} />

      <div className="cattleMap_page">
        <div className="cattleMap_header">
          <BagFood height={72} />
          <div style={{
            display: "flex",
            flexDirection: "column",
          }}>
            <h2 className="cattleMap_title">
              Suplementação
            </h2>
            <h3 className="cattleMap_subtitle">
              Controle de estoque
            </h3>
          </div>
        </div>

        <ViewDataSupplementation />

        {centralWarehouse &&
          <div className="tableDashboard" id={"estoque_suple"}>
            <h2>Estoque deposíto central</h2>
            <table>
              <thead style={{
                color: "#fff"
              }}>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "300px",
                  textAlign: "left"
                }}>
                  LINHA DE SUPLEMENTAÇÂO
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "180px",
                  padding: "0 20px"
                }}>
                  PRODUTO
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "180px",
                }} >
                  ESTOQUE TOTAL (Kg)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "180px",
                  padding: "0 20px"
                }}>
                  MÉDIA DE SAÍDAS DIÁRIAS
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "180px"
                }}>
                  DIAS EM ESTOQUE (dias)
                </th>
              </thead>

              <tbody>
                {
                  centralWarehouse?.map((item, index) => (
                    <tr className={index % 2 === 0 ? " bg_white" : "bg_gray"}>
                      <th scope="row">{item.name}</th>
                      <td style={{
                        textAlign: "left"
                      }}>{item.trade_name}</td>
                      <td>{item.actual_quantity}</td>
                      <td>{item.average_daily_output}</td>
                      <td>{item.days_in_stock}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        }


        {
          <div className="tableDashboard" id={"analise_geral"}>
            <h2>Análise Geral</h2>
            <table>
              <thead style={{
                color: "#fff"
              }}>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "180px",
                  textAlign: "left"
                }}>
                  CLASSIFICAÇÃO DO PRODUTO
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "200px",
                  padding: "0 20px"
                }}>
                  CONSUMO TOTAL NO PERÍODO (Kg)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "180px",
                }} >
                  PERÍODO ANALISADO (dias)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "150px",
                  padding: "0 20px"
                }}>
                  ANIMAIS SUPLEMENTADOS
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "250px"
                }}>
                  PESO MÉDIO DOS ANIMAIS SUPLEMENTADOS (média de Kg)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "240px",
                  padding: "0 20px"
                }}>
                  PESO TOTAL DOS ANIMAIS SUPLEMENTADOS (Kg)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "210px",
                }} >
                  UAs TOTAL DE ANIMAIS SUPLEMENTADOS
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "180px",
                  padding: "0 20px"
                }}>
                  CONSUMO MÉDIO NO PERÍODO (g/kgPV.dia)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "180px"
                }}>
                  CONSUMO MÉDIO NO PERÍODO  (g/UA.dia)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "180px",
                  padding: "0 20px"
                }}>
                  FREQUÊNCIA DE REPOSIÇÃO (média de dias)
                </th>
              </thead>

              <tbody>
                {
                  [{
                    linha: "Sal Mineral",
                    con_total: 32840,
                    periodo_ana: 30,
                    ani_suplem: 12764,
                    pes_medio: 392,
                    pes_total: 5009680,
                    uas_total: 11133,
                    cons_medioKGPV: 85.8,
                    cons_medioUA: 98.3,
                    freq: 2.3
                  },
                  {
                    linha: "Proteico",
                    con_total: 153240,
                    periodo_ana: 30,
                    ani_suplem: 5923,
                    pes_medio: 400,
                    pes_total: 2371340,
                    uas_total: 5270,
                    cons_medioKGPV: 862.4,
                    cons_medioUA: 969.2,
                    freq: 3.0
                  },
                  {
                    linha: "Ração",
                    con_total: 32840,
                    periodo_ana: 30,
                    ani_suplem: 12764,
                    pes_medio: 392,
                    pes_total: 5009680,
                    uas_total: 11133,
                    cons_medioKGPV: 85.8,
                    cons_medioUA: 98.3,
                    freq: 2.3
                  }].map((item, index) => (
                    <tr className={index % 2 === 0 ? " bg_white" : "bg_gray"}>
                      <th scope="row">{`${item.linha}`}</th>
                      <td>{item.con_total}</td>
                      <td>{item.periodo_ana}</td>
                      <td>{item.ani_suplem}</td>
                      <td>{item.pes_medio}</td>
                      <td>{item.pes_total}</td>
                      <td>{item.uas_total}</td>
                      <td>{item.cons_medioKGPV}</td>
                      <td>{item.cons_medioUA}</td>
                      <td>{item.freq}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        }

        {depositeInPasture &&
          <div className="tableDashboard" id={"estoque_pasto"}>
            <h2>Estoque depósito no pasto</h2>
            <table>
              <thead style={{
                color: "#fff"
              }}>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "180px",
                  textAlign: "left"
                }}>
                  PASTO
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "180px",
                  padding: "0 20px"
                }}>
                  TIPO DE SUPLEMENTO
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "180px",
                }} >
                  QUANTIDADE NO DEPÓSITO DO PASTO (Kg)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "180px",
                  padding: "0 20px"
                }}>
                  CAPACIDADE TOTAL DO DEPÓSITO (Kg) *
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "180px"
                }}>
                  % DA CAPACIDADE OCUPADA DO DEPOSITO *
                </th>
              </thead>

              <tbody>
                {
                  depositeInPasture?.map((item, index) => (
                    <tr className={index % 2 === 0 ? "bg_white" : "bg_gray"}>
                      <th scope="row">{item.initials}</th>
                      <td style={{
                        textAlign: "left"
                      }}>{item.name}</td>
                      <td>{item.stock_balance}</td>
                      <td>{item.stock_capacity}</td>
                      <td style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%"
                      }}>
                        {IconMarq((item.stock_balance / item.stock_capacity), 30, 50)}
                        {item.stock_capacity ? item.stock_balance / item.stock_capacity : 0} %
                      </td>
                    </tr>
                  ))
                }
              </tbody>

              <tfoot
                style={{
                  background: "#324440",
                  color: "#fff"
                }}
              >
                <th scope="row" style={{
                  textAlign: "left"
                }}>Total geral</th>
                <td>-</td>
                <td>{depositeInPasture?.reduce((acc, item) => acc + item.stock_balance, 0)} kg</td>
                <td>{depositeInPasture?.reduce((acc, item) => acc + item.stock_capacity, 0)} kg</td>
                <td>
                  <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%"
                  }}>
                    {IconMarq(29, 30, 50)}{0} %
                  </div>
                </td>
              </tfoot>
              <span>*Valores serão demonstrados após a realização de uma avaliação de pastagem</span>

            </table>

            <div className="tableDashboard" id={"estoque_suple"}>
              <table>
                <thead style={{
                  color: "#fff"
                }}>
                  <th scope="col" rowSpan={2} style={{
                    background: "#324440",
                    width: "150px",
                    textAlign: "left"
                  }}>
                    LEGENDA
                  </th>

                  {[{
                    legenda: "<=30%"
                  },
                  {
                    legenda: ">=31% <=50%"
                  },
                  {
                    legenda: ">50%"
                  }].map((item, index) => (
                    <th scope="col" rowSpan={2} style={{
                      background: "#324440",
                      width: "150px",
                      textAlign: "left"
                    }}>
                      {item.legenda}
                    </th>
                  ))}
                </thead>

                <tbody>
                  <tr className="bg_white">
                    <th scope="row"></th>
                    <td style={{
                      textAlign: "left"
                    }}>{IconMarq(1, 30, 50)}</td>
                    <td style={{
                      textAlign: "left"
                    }}>{IconMarq(35, 30, 50)}</td>
                    <td style={{
                      textAlign: "left"
                    }}>{IconMarq(55, 30, 50)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        }
      </div>
    </div>
  );
}
